header {
    padding: 50px 40px 20px;
}

header .pages {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

header .pages a {
    padding-right: 15px;
    color: #293440 !important;
    text-decoration: none;
}

header .pages a:hover {
    text-decoration: underline;
}

header .login-wrapper {
    background-color: #f4f4f4;
    border-radius: 25px;
    padding: 7px 25px;
}

header .login-wrapper button {
    color: black !important;
    text-decoration: none;
    border: none;
    padding: 0;
}
header .login-wrapper a {
    color: black !important;
    text-decoration: none;
}

header .login-wrapper a:hover {
    text-decoration: underline;
}

.bg {
    background-image: url("../images/background.jpg");
    background-size: contain;
    padding: 30px 40px;
}

.sign-wrapper {
    border: 2px solid #b8b8b8;
    text-align: center;
    padding: 10px;
    background-color: white;
}

.logo-wrapper {
    border: 2px solid #b8b8b8;
    border-radius: 25px 0 25px 25px;
    text-align: center;
    padding: 20px;
    background-color: white;
}

.search-button {
    border-radius: 25px;
    background-color: #f4f4f4;
    border: 2px solid white;
    padding: 6px 25px;
    cursor: pointer;
}

.content {
    margin-top: 45px;
    background-color: white;
}

.content-top {
    width: 100%;
    height: 45px;
}

.content-top tr td {
    border: 2px solid #b8b8b8;
}

main,
.main {
    padding: 40px 30px;
}

footer {
    border: 2px solid #b8b8b8;
    margin-top: 45px;
    background-color: white;
    border-radius: 25px 0 25px 25px;
    padding: 20px 30px;
}

.copy {
    font-size: 0.8rem;
}

.copy a {
    font-size: 0.8rem;
    color: #293440 !important;
    text-decoration: underline;
}

.home-href {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

.search-select > div {
    border-radius: 0;
}

.form-select > div {
    border-radius: 0;
    background: #F4F4F4;
    width: 100%;
    border: none;
    margin-bottom: 5px;
}

.article p,
table {
    color: #6E6E6E;
}

.article strong,
.article a {
    color: black !important;
}

.article a {
    text-decoration: underline;
}

.article ul {
    list-style-type: none;
}
.article ul li {
    position: relative;
}
.article ul li::before {
    position: absolute;
    content: "";
    display: block;
    background-image: url("../images/list.png");
    width: 17px;
    height: 17px;
    background-size: contain;
    left: -22px;
    top: 4px;
}

table.styled tr td {
    padding: 10px 15px;
    background-color: #F4F4F4;
    border: 3px solid white;
}

table.styled:not(.left) tr:first-child td,
table.styled.left tr td:first-child {
    background-color: #DFDFDF;
    color: black;
    font-size: 1.1rem;
}
table.styled.left.detail-list tr td:first-child {
    white-space: nowrap;
}
table.styled.left.detail-list tr td:nth-child(2) {
    width: 100%;
}

form input:not([type=submit]) {
    background: #F4F4F4;
    padding: 10px 15px;
    width: 100%;
    border: none;
    margin-bottom: 5px;
}

form input[type=checkbox] {
    width: 15px;
    margin: 0 !important;
}

.input-group-text {
    background: #F4F4F4 !important;
    border: none !important;
}

form input[type=submit] {
    background: #F4F4F4;
    padding: 7px 15px;
    border: none;
    border-radius: 40px;
    display: block;
    margin: 15px 0 0 auto;
}

.header {
    display: flex;
    padding: 10px 15px;
    background-color: #E8E8E8;
    margin-bottom: 20px;
    min-height: 60px;
    align-items: center;
}
.header .text {
    font-size: 1.4rem;
}

.box {
    position: relative;
    padding-top: 53.45%;
    display: flex;
    width: 100%;
    border-radius: 10px;
    border: 2px solid #AEADAD;
    cursor: pointer;
    background-image: url("../images/box.png");
    background-size: contain;
    background-repeat: no-repeat;
}
.box .inner {
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 1.2rem;
    right: 17%;
    bottom: 20%;
    width: 66%;
    text-align: right;
}

.document-category-list button {
    background: #E8E8E8;
    padding: 7px 15px;
    border: none;
    border-radius: 40px;
    display: block;
    width: 100%;
    margin-bottom: 5px;
    text-align: left;
}

.document-list .document {
    background: #F4F4F4;
    padding: 7px 10px 7px 15px;
    margin-bottom: 5px;
}

.document-list .document-info {
    font-size: 0.9rem;
    font-weight: bold;
    text-align: right;
}

.document-list .download {
    background: white;
    padding: 3px 15px;
    border: 2px solid #E8E8E8;
    border-radius: 40px;
    display: block;
    width: 100%;
    text-align: center;
    color: black;
    text-decoration: none !important;
}

.advertisement-button {
    background: white;
    padding: 7px 35px;
    border: 2px solid #9C9C9C;
    border-radius: 40px;
    display: inline-flex;
    margin-left: 10px;
    text-align: center;
    color: black !important;
    text-decoration: none !important;
}

.search-detail {
    background: white;
    padding: 7px 35px;
    border-radius: 40px;
    border: none;
    display: inline-flex;
    margin-left: auto;
    color: black !important;
    text-decoration: none !important;
}

.lost-password {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    color: black !important;
}

.header-logo {
    max-width: 550px !important;
}

.header-symbol {
    max-height: 115px !important;
}

@media only screen and (max-width: 768px) {
    .article table:not(.styled),
    .article table:not(.styled) tbody,
    .article table:not(.styled) thead,
    .article table:not(.styled) tr,
    .article table:not(.styled) td,
    .article table:not(.styled) th,
    table.detail-list,
    table.detail-list tbody,
    table.detail-list thead,
    table.detail-list tr,
    table.detail-list td,
    table.detail-list th{
        display: block !important;
    }

    .article img {
        max-width: 100%;
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .pages,
    .pages a {
        display: block !important;
        text-align: center;
    }

    .pages {
        margin-bottom: 25px;
    }
    .login-wrapper {
        margin: auto !important;
    }

    .document-list .list .document {
        text-align: center;
    }
    .document-list .list .document .search-detail {
        margin: 15px auto 10px auto;
    }
}
